<template>
	<div class="flex-col page">
		<div class="flex-col title-box">
			<div class="flex-col text-wrapper">
				<span class="title">{{ StateName }}</span>
			</div>
		</div>
		<div class="flex-col box-con">
			<div class="flex-col">
				<van-steps active-color="rgb(0, 104, 255)" :active="State">
					<van-step>工单派发</van-step>
					<van-step>已接单</van-step>
					<van-step>处理中</van-step>
					<van-step>完成工单</van-step>
				</van-steps>
				<span class="order-type-title">维保工单</span>
			</div>
			<div class="list">
				<div class="list-item flex-row" :key="i" v-for="(title, i) in list">
					<span class="list-label">{{ title.title }}</span>
					<span :class="['list-value', 'look']" v-if="title.key == 'item'" @click="more()">{{ title.value }}</span>
					<span :class="['list-value', { red: title.key == 'StateName' }]" v-else>{{ title.value }}</span>
				</div>
			</div>
			<div v-if="State == '3'" class="list">
				<div class="list-item flex-row">
					<span class="list-label">完工签到</span>
				</div>
				<div class="flex-row equal-division" style="display: block">
					<van-row justify="center">
						<van-col span="6" v-for="(item, t) in fileList" :key="t">
							<van-image :src="item" width="5rem" height="5rem" fit="cover" />
						</van-col>
					</van-row>
				</div>
			</div>
			<div class="list">
				<div class="list-item flex-row">
					<span  >物业备注</span>
					<span v-if="PropertyRemark" :class="['list-value']">{{ PropertyRemark }}</span>
				</div>
				<textarea v-if="!PropertyRemark" id="textarea" placeholder="请输入物业备注" cols="10" rows="5"></textarea>
			</div>
			<div class="list">
				<div class="list-item flex-row">
					<span >物业评分</span>
					<van-rate v-model="rate" @change="rateChange" size="30px" gutter="9px" />
				</div>
			</div>
			<div class="list">
				<div class="list-item flex-row">
					<span >物业签名</span>
					<van-image v-if="PropertySign" :src="PropertySign" width="5rem" height="5rem" fit="cover" />
				</div>
			</div>

			<sign-canvas
				:is-cheat="submitExamEdit.isCheat"
				:show="showCanvas"
				@cancle="cancleCanvas"
				@save="saveCanvas"
				@clear="clearCanvas"
			/>
			<div v-if="!PropertySign" class="button flex-col items-center text-wrapper_1" @click="sign()">
				<span>确认签字</span>
			</div>
		</div>
	</div>
</template>

<script>
import signCanvas from "@/components/signCanvas";

import { uploadSign } from "@/api/file";
import { detail } from "@/api/Property/Maintenance/all";
import { PropertySign } from "@/api/Property/Order/all";

import { Toast } from "vant";
export default {
	components: {
		signCanvas,
	},
	data() {
		return {
			list: [
				{ title: "工单号", key: "MaintenanceId", value: "" },
				{ title: "小区名称", key: "HousesName", value: "" },
				{ title: "电梯位置", key: "address", value: "" },
				{ title: "电梯名称", key: "Number", value: "" },
				{ title: "设备代码", key: "RegisterNumber", value: "" },
				{ title: "维保类型", key: "Type", value: "" },
				{ title: "维保内容", key: "MaintenanceContent", value: "" },
				{ title: "维保项", key: "item", value: "查看维保项" },
				{ title: "维保员", key: "CompanyStaffName", value: "" },
				{ title: "维保人电话", key: "CompanyStaffPhone", value: "" },
				{ title: "工单时间", key: "ReceiveTime", value: "" },
				{ title: "工单状态", key: "StateName", value: "" },
			],
			fileList: [],

			State: 0, //工单状态
			StateName: "", //工单状态
			PropertySign: "",
			PropertyRemark: "",
			rate: 5,

			showCanvas: false,
			submitExamEdit: {
				isCheat: false,
			},
		};
	},
	methods: {
		rateChange(value) {
			if (value < 1) {
				this.rate = 1;
			}
		},
		more() {
			this.$router.push("/property/maintenance/maintenance-item/" + this.$route.params.id);
		},
		sign() {
			//点击签字
			this.showCanvas = true;
		},
		cancleCanvas() {
			this.showCanvas = false;
			console.log("取消画布");
		},
		clearCanvas() {
			console.log("清除画布");
		},
		saveCanvas(file) {
			this.$toast.loading();
			var formData = new FormData();
			formData.append("file", file);
			uploadSign(formData)
				.then((response) => {
					this.$toast.clear();
					this.showCanvas = false;
					let data = {
						PropertySign: response.data.Data,
						PropertyRemark: textarea.value,
						id: String(this.$route.params.id),
						type: "maintenance",
						rate: this.rate,
					};
					PropertySign(data).then((res) => {
						console.log(res);
						if (res.data.Result === 200) {
							Toast("签字成功");
							this.$router.push("/property/index");
						} else {
							Toast("签字失败，请检查是否全部填写");
						}
					});
				})
				.catch(() => {
					this.$toast.clear();
				});
		},
	},
	mounted() {
		detail({ id: String(this.$route.params.id) }).then((res) => {
			let resData = res.data.Data;
			console.log(resData);
			
			this.list = this.$common.setList(this.list, resData); //更新列表值;
			this.State = resData.State;
			this.StateName = resData.StateName;

			let fileList = resData.MaintenanceProcessPhotosOfVideo;
			this.fileList = fileList.split(",");
			this.PropertySign = resData.PropertySign; //判断是否签名
			

			this.PropertyRemark = resData.PropertyRemark;
			this.rate = resData.rate;
		});
	},
};
</script>

<style scoped>
@import "../../../assets/common.css";
.van-rate {
	margin-left: 2rem;
}
.order-type-title {
	margin-top: 0.94rem;
	margin-bottom: 0.94rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
}
.button {
	color: rgb(255, 255, 255);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	padding: 1.06rem 0;
	background-image: url("../../../assets/buttonbg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.list {
	padding-bottom: 1.06rem;
}
.list-item {
	border-bottom: solid 0.063rem rgb(243, 249, 244);
	height: 3rem;
} 
.list-label {
	color: rgb(179, 179, 178);
	font-size: 0.75rem;
	line-height: 3rem;
	white-space: nowrap;
	display: inline-block;
	width: 5rem;
}

.list-value {
  /* 列表项 值 样式 */
	margin-left: 3.44rem;
	color: rgb(101, 102, 101);
	font-size: 0.88rem;
	line-height: 3rem;
	white-space: nowrap;
}

.page {
  /*  页面 样式 */
	padding-bottom: 1.56rem;
	background-color: rgb(255, 255, 255);
	height: 100%;
	width: 100%;
	overflow-y: auto;
}
.title-box {
	padding: 0 0 1.69rem;
}
.box-con {
	margin-top: -2.56rem;
	padding: 1.69rem 1.25rem 0;
	background: #fff;
	border-radius: 1rem;
}
 
.text-wrapper {
	padding: 1.88rem 0 3.44rem;
	color: rgb(255, 255, 255);
	font-size: 1.5rem;
	line-height: 1.44rem;
	white-space: nowrap;
	background-image: url(/img/imgbg1_03.7f7ef7cb.png);
	background-size: cover;
	background-repeat: no-repeat;
}

.text-wrapper_1 {
	margin-top: 1.88rem;
}
.text-wrapper_2 {
	margin-top: 2rem;
}

.title {
	margin-left: 1.5rem;
}
  

.look {
	color: #005ddb;
}
.red {
	color: red;
} 

.equal-division-item_1 {
	flex: 1 1 5.38rem;
	padding: 0.31rem 0.25rem 0.31rem 0.31rem;
}
 
.equal-division {
	margin-top: 0.25rem;
	color: rgb(0, 83, 215);
	font-size: 0.75rem;
	line-height: 0.75rem;
	white-space: nowrap;
}
#textarea {
	width: 100%;
	color: rgb(179, 179, 178);
	background: #f8f9fd;
	border: none;
	border-radius: 0.4rem;
	margin-top: 0.8rem;
	box-sizing: border-box;
	padding: 0.6rem;
}
</style>
