/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-16 01:02:43
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-26 19:47:20
 */
// 维保日常
import request from '@/request/request'
// 详细数据
export function detail(data){
    return request({
        url:'/Property/Maintenance/Info',
        method:'post',
        data
    })
}
// 维保列表项
export function itemList(data){
    return request({
        url:'/Property/Maintenance/ItemList',
        method:'post',
        data
    })
}
